/* Media query */
@mixin media-query($size, $type: min, $horizontal: width) {
    @if $type == max {
        $size: $size - 1px;
    }
    @media only screen and (#{$type}-#{$horizontal}: $size) {
        @content;
    }
}

@mixin user-select($value: none) {
	-webkit-touch-callout: $value; /* iOS Safari */
	  -webkit-user-select: $value; /* Safari */
	   -khtml-user-select: $value; /* Konqueror HTML */
		 -moz-user-select: $value; /* Old versions of Firefox */
		  -ms-user-select: $value; /* Internet Explorer/Edge */
			  user-select: $value; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

@mixin user-drag($value: none) {
	-webkit-user-drag: $value;
	-khtml-user-drag: $value;
	-moz-user-drag: $value;
	-o-user-drag: $value;
	user-drag: $value;
}

@mixin transition($args...) {
	-webkit-transition: $args;
	   -moz-transition: $args;
	    -ms-transition: $args;
	     -o-transition: $args;
	        transition: $args;
}

@mixin transform($args...) {
	-webkit-transform: $args;
	   -moz-transform: $args;
	    -ms-transform: $args;
	     -o-transform: $args;
	        transform: $args;
}

@mixin filter($value) {
  	-webkit-filter: $value;
	   -moz-filter: $value;
		 -o-filter: $value;
			filter: $value;
}

@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin border-gradient() {
	&:after {
		@content;
	}
}

@function color-alpha($color, $opacity) {
	@return rgba($color, $opacity);
}
