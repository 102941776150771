/* COLOR */
:root {
	--color-primary: #821c0d;
	--color-primary-1: #a43a16;
	--color-secondary: #cda66f;
	--color-secondary-1: var(--color-secondary);
	--color-secondary-2: #3f4e3b;
	--color-secondary-3: #a0a884;

	// color
	--color-black: #000000;
	--color-black2: #181818;
	--color-grey: #989899;
	--color-white: #ffffff;
	--color-dark2: #585858;
	--color-grayscale: #f7f7fc;

	// text
	--color-text__title: #240e0a;
	--color-text__body: #4b4747;
	--color-text__label: #8b8887;
	--color-text__placeholder: #cec6c5;
	--color-text__disabled: #e7e7e7;
	--color-text__btn_bold: var(--color-button__bold);

	// background
	--color-background: #ffffff;
	--color-background__bold: #e7d6c6;
	--color-background__light: #fbf7f1;
	--color-background__white: #ffffff;
	--color-background__disabled: #e7e7e7;

	// border
	--color-border: #cec6c5;

	// button
	--color-button__light: #f2d5a9;
	--color-button__bold: #d36c26;

	// genealogy
	--color-male: #81aace;
	--color-male__background: #f6f5ff;

	--color-female: #f06e67;
	--color-female__background: #fffbf1;

	--color-male__dead: #001e38;
	--color-female__dead: #592425;

	--color-adopted: #96d8d4;
	--color-adopted-background: #f4f6f6;

	--color-marriage: #b99ff1;
	--color-marriage__background: #f5f0ff;

	--color-male__text: #1f4e77;
	--color-female__text: #ba4740;

	// status
	--color-success: #14924e;
	--color-warning: #df5e17;
	--color-info: #3734c2;
	--color-all: #2b7a93;
	--color-danger: #ff414c;

	// carousel
	--carousel-bullet__color: #d9d9d9;
	--carousel-bullet__color-active: var(--color-primary);

	// request
	// color-spend lighten
	--color-pending-request: hsla(21, 81%, 48%, 60%);

	// form
	--color-input__adornment: #e02526;

	--color-yellow-fade-effect: #f6cd61;

	--header-bar-height: 68px;
}

/* FONT */
// FONT VARIANT
$font-variant__text: Inter;
$font-variant__header: PlayfairDisplay;

// Font size
$font-size__base: 16px;

$font-size__xxs: 0.5rem; // 8px
$font-size__xs: 0.625rem; // 10px
$font-size__sm: 0.75rem; // 12px
$font-size__semi-sm: 0.875rem; // 14px
$font-size: 1rem; // 16px
$font-size__semi-md: 1.125rem; // 18px
$font-size__md: 1.25rem; // 20px
$font-size__lg: 1.5rem; // 24px
$font-size__semi-xl: 1.75rem; // 28px
$font-size__xl: 2rem; // 32px
$font-size__xxl: 2.25rem; // 36px
$font-size__xxxl: 3rem; // 48px
$font-size__4xl: 4rem; // 64px

/* SPACING */
$margin: 16px;
$padding: 16px;

$input-height: 54px;

$icomoon-font-family: "icofont" !default;
$icomoon-font-path: "../../fonts" !default;

/* BORDER */
$border-radius: 10px;
