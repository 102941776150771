#pages_gia-pha {
	width: 100%;
	padding: 0;
	margin: 0;
	min-height: 100vh;
	
	background-image: url("../../images/bg-header.png");
	background-position: center;
	background-repeat: repeat;
	background-size: 40px;

	.image {
		display: block;
		margin: 0 auto;
		max-width: 100%;
		&__content {
			width: 1024px;
			max-width: 100%;
		}
	}

	.header {
		background-image: url("../../images/bg-header.png");
		background-position: center;
		background-repeat: repeat;
		background-size: 40px;
		width: 100%;
		height: 60px;
		position: fixed;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.logo {
		width: 40px;
		height: 40px;
	}

	.container {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding-top: 60px;
	}

	.content {
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.info {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 20px;
		padding-bottom: 90px;
		width: 100%;
		max-width: 1024px;
		&__wrapper { 
			width: 100%;
			background-color: #fbf7f1;
			display: flex;
			justify-content: center;
			align-content: center;
		}
		&__headerText {
			font-family: $font-variant__header;
			font-size: 20px;
			font-weight: 600;
			line-height: 28px;
			color: var(--color-text__black);
			text-transform: uppercase;
			text-align: center;
		}

		&__toc {
			background-color: var(--color-button__light);
			border-radius: 10px;
			padding: 12px 24px;
			margin-top: 14px;
			text-align: center;

			&__ten {
				font-family: $font-variant__header;
				font-size: 24px;
				font-weight: 700;
				line-height: 32px;
				color: var(--color-primary);
				text-transform: uppercase;
				text-align: center;
			}
		}

		&__diachi {
			margin-top: 14px;
			font-family: $font-variant__header;
			font-size: 14px;
			font-style: italic;
			line-height: 20px;
			color: var(--color-text__black);
			text-align: center;
		}

		&__cards {
			margin-top: 14px;
			padding: 0 32px;
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			grid-row: 2;
			gap: 32px;
			width: 100%;

			&__card {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding-top: 8px;
				padding-bottom: 16px;
				width: 48%;
				background-color: var(--color-white);
				border: 1px solid var(--color-button__light);
				border-radius: 10px;

				&__title {
					font-family: $font-variant__header;
					font-size: 16px;
					font-weight: 700;
					line-height: 24px;
					color: var(--color-text__black);
					text-align: center;
				}

				&__dashed {
					border-bottom: 1px dashed var(--color-button__light);
					width: calc(100% - 32px);
					height: 1px;
					margin-top: 8px;
					margin-bottom: 16px;
				}

				&__value {
					font-family: $font-variant__header;
					font-size: 30px;
					font-weight: 700;
					line-height: 36px;
					color: var(--color-primary);
				}
			}
		}

		&__ngayin {
			margin-top: 20px;
			font-family: $font-variant__text;
			font-size: 16px;
			line-height: 24px;
			color: #727272;
			text-align: center;
		}

		&__thongtinbaohanh {
			margin-top: 20px;
			font-family: $font-variant__text;
			font-size: 20px;
			line-height: 28px;
			font-weight: 600;
			color: var(--color-text__black);
			text-transform: uppercase;
			text-align: center;
		}

		&__tickcircleIcon {
			width: 30px;
			height: 30px;
			margin-top: 14px;
		}

		&__qua-tang-Icon {
			width: 50px;
			height: 50px;
			margin-top: 15px;
			margin-bottom: 12px;
		}

		&__thoigianbaohanhcon {
			margin-top: 8px;
			font-family: $font-variant__text;
			font-size: 14px;
			line-height: 20px;
			color: var(--color-text__black);
		}

		&__xngay {
			margin-top: 8px;
			font-family: $font-variant__text;
			font-size: 30px;
			line-height: 36px;
			color: var(--color-success);
			font-weight: 600;
			margin-bottom: 16px;
		}

		&__han {
			font-family: $font-variant__text;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 160%;
			text-align: center;
			color: #000000;
			padding: 0 32px;
		}

		&__redirectto {
			margin-top: 16px;
			font-family: $font-variant__text;
			font-size: 14px;
			line-height: 20px;
			font-weight: 600;
			color: var(--color-text__black);
			text-transform: uppercase;
			text-decoration-line: underline;
			background: none;
			color: inherit;
			border: none;
			cursor: pointer;
		}

		&__banchuaco {
			margin-top: 36px;
			font-family: $font-variant__text;
			font-size: 14px;
			line-height: 20px;
			color: var(--color-text__black);
			font-style: italic;
			text-align: center;
		}

		&__sohuungay {
			background-color: var(--color-primary);
			border-radius: 10px;
			padding: 12px 24px;
			margin-top: 14px;
			font-size: 18px;
			font-weight: 500;
			color: var(--color-white);
			border: none;
			text-transform: uppercase;
			cursor: pointer;
			font-family: $font-variant__text;
			font-style: normal;
			line-height: 28px;
			display: flex;
			align-items: center;
			text-align: center;
			letter-spacing: 0.02em;
		}

		&__dahethanbaohanh {
			margin-top: 8px;
			font-family: $font-variant__text;
			font-size: 18px;
			line-height: 28px;
			font-weight: 600;
			color: var(--color-primary);
			text-transform: uppercase;
			margin-bottom: 16px;
		}

		&__caygiapha {
			margin-top: 22px;
			font-family: $font-variant__header;
			font-style: normal;
			font-weight: 600;
			font-size: 40px;
			line-height: 53px;
			color: var(--color-primary);
		}

		&__khongtontai {
			margin-top: 10px;
			font-family: $font-variant__text;
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			line-height: 24px;
			text-align: center;
			letter-spacing: 0.02em;
			text-transform: uppercase;
			color: #8b8887;
			margin-bottom: 18px;
		}
	}
}
