.notification-container {
    min-height: 100vh;
    background-color: rgb(251, 247, 241);
    display: flex;
    justify-content: center;
    align-items: center;
    .text-notification {
        font-family: $font-variant__header;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #240E0A;
    }
    .image-notification {
        margin: 0px auto;
        display: block;
        max-width: 80%;
        width: 480px;
    }
}
