@font-face {
	font-family: $font-variant__header;
	src: url("../../fonts/PlayfairDisplay-Bold.ttf");
	font-weight: 700;
}

@font-face {
	font-family: $font-variant__text;
	src: url("../../fonts/Inter-Regular.ttf");
	font-weight: 400;
}

@font-face {
	font-family: $font-variant__text;
	src: url("../../fonts/Inter-Medium.ttf");
	font-weight: 500;
}

@font-face {
	font-family: $font-variant__text;
	src: url("../../fonts/Inter-SemiBold.ttf");
	font-weight: 600;
}
