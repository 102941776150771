.footer {
    background-size: 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 15px;
    .footer-logo {
            height: 90px;
            width: 480px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        img {
            height: 65px;
            width: 65px;
        }
    }
    p {
        font-family: Inter;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.03em;
        color: #ffff;
        margin: 0 10px;
    }
    li {
        list-style: none;
        list-style-type: none;
        font-family: Inter;
        line-height: 130%;
        letter-spacing: 0.1px;
        margin-left: 0;
    }
    a {
        font-family: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        color: inherit;
        margin-bottom: 16px;
    }
    .download-app {
        a {
            margin: 5px;
        }
        img {
            height: auto;
            width: 100px;
            margin: 0;
        }
    }
}
