*,
*:before,
*:after {
	margin: 0 0;
	padding: 0 0;
	box-sizing: border-box;
	outline: none;
	-webkit-tap-highlight-color: transparent;
	@include user-select(none);
}

input, textarea {
	@include user-select(initial);
}

a {
	text-decoration: none;
}

li {
	list-style: none;
	list-style-type: none;
}

a, img {
	@include user-drag(none);
}
